import feedback, { Feedback } from './feedback';
import FeedbackEvents from './feedbackEvents';

declare global {
    interface Window {
        FeedbackEvents: FeedbackEvents;
        Feedback: Feedback;
    }
}

export default feedback;

window.FeedbackEvents = FeedbackEvents;
window.Feedback = feedback;

export {
    FeedbackEvents,
};
