import FeedbackLoader from './dist/FeedbackLoader/';
import EventsLogger from './dist/EventsLogger';

export type Survey = {
    delay: number;
    feature: string;
    internal?: boolean;
    permille: number;
    repeatAfterDays?: number;
    showAfterDays?: number;
    surveyId: string | number;
    triggerEvents?: {
        type: string;
        minDelay?: number;
    }[];
};

export type FeedbackOptions = {
    styles?: Partial<CSSStyleDeclaration>,
    surveys: Survey[],
    clientId?: null | string,
    projectName: string,
    observeTimeout?: number
    forceShowSurveyId?: string,
}

const DEFAULT_OBSERVE_TIMEOUT = 5000;

export class Feedback {
    clientId = '';
    feedbackLoader?: FeedbackLoader;
    eventsLogger?: EventsLogger<string>;
    inited = false;

    get initialized(): boolean {
        return this.inited;
    }

    trigger = (event: string): void => {
        if (this.initialized && this.eventsLogger) {
            this.eventsLogger.push(event);
        }
    }

    init = (options: FeedbackOptions): void => {
        if (this.initialized) {
            return;
        }

        const {
            styles,
            surveys,
            projectName,
            observeTimeout = DEFAULT_OBSERVE_TIMEOUT,
            forceShowSurveyId
        } = options;

        this.clientId = options.clientId || FeedbackLoader.getClientId();

        this.eventsLogger = new EventsLogger(this.clientId);

        const loaderOptions = {
            forceShowSurveyId,
            surveyLoaderOptions: {
                clientId: this.clientId,
                eventsLogger: this.eventsLogger,
                apiBaseUrl: 'https://corsapi.imgsmail.ru/api/v1',
                frameLoaderOptions: {
                    project: projectName,
                    styles: { zIndex: 9999, ...styles },
                    darkTheme: true,
                }
            }
        };

        this.feedbackLoader = new FeedbackLoader(surveys, loaderOptions);
        this.feedbackLoader.observe(observeTimeout);

        this.inited = true;
    }

    close = (): void => {
        if (!this.initialized) {
            return;
        }

        this.clientId = '';

        this.eventsLogger = undefined;

        if (this.feedbackLoader) {
            this.feedbackLoader.disconnect();
            this.feedbackLoader.close();
            this.feedbackLoader = undefined;
        }

        this.inited = false;
    }
}

const feedback = new Feedback();

export const triggerEvent = (event: string): void => {
    feedback.trigger(event);
};

export default feedback;
