import { triggerEvent } from './feedback';

class FeedbackEvents {
    static trigger(event: string): void {
        triggerEvent(event);
    }

    static eventsWithDelay = new Map();

    static triggerWithDelay(event: string, delay = 0, watchPath = true): void {
        if (FeedbackEvents.eventsWithDelay.has(event)) {
            return;
        }

        FeedbackEvents.eventsWithDelay.set(event, true);

        const initPath = window.location.pathname;

        setTimeout(() => {
            FeedbackEvents.eventsWithDelay.delete(event);
            const pathAfterTimeout = window.location.pathname;

            if (pathAfterTimeout === initPath || !watchPath) {
                FeedbackEvents.trigger(event);
            }
        }, delay);
    }
}

export default FeedbackEvents;
